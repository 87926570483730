@import "../variables.less";

.section.download-form, .section.demo-form {
  overflow: hidden;

  .container {
    padding-top: 138px;
    padding-bottom: 138px;
    position: relative;
    .m({
      background: url(../assets/header-fade.svg) no-repeat;
      background-position: center top 135px;
      background-size: 100% auto;
      padding-top: 320px;
      text-align: center;

      .form-group textarea.input-textarea,
      .form-group input.input-text,
      .form-group.submit-action input {
        width: 100%;
      }
    });

    .image-block {
      position: absolute;
      left: 50%;
      margin-left: 113px;
      height: 95%;
      top: 50%;
      transform: translateY(-50%);

      img {
        height: 100%;
        width: auto;
        object-fit: contain;
      }

      .m({
        width: 416px;
        left: 0;
        margin-left: 0;
        transform: 0;
        z-index: -1;
        img {
          height: auto;
          width: 100%;
        }
      });
    }

    .form-group {
      textarea.input-textarea,
      input.input-text {
        float: none;
        margin-bottom: 8px;
      }

      &.submit-action {
        //	input{ float: right;}
      }
    }
  }
}


.f--checkbox {
  display: flex !important;
  width: 100%;
  //max-width:288px;
  margin-bottom: 10px;
  color: #092984;
  align-items: flex-start;
  margin-top: 5px;

  input {
    width: auto !important;

    margin-right: 10px;
    margin-top: 5px;
    margin-bottom: 0 !important;
  }

  &.f--checkbox--last {
    margin-bottom: 20px;
  }

  label {
    display: block !important;
    text-align: left !important;
  }
}

.freeform-column.checkbox {
  padding-top: 0 !important;
  padding-bottom: 0 !important;

  label {
    display: flex !important;

    input {
      margin-right: 10px;
      margin-top: 5px;

    }
  }
}

.freeform-row {
  display: flex;
  justify-content: space-between;
  margin: 0 -15px;

  .freeform-column {
    flex: 1 0;
    padding: 10px 0;
    margin: 0 15px;
    box-sizing: border-box;

    input {
      width: 100%;
      display: block;
      box-sizing: border-box;
    }
  }
}


.form-group__field-error {
  color: red;
  margin-bottom: 10px;
}

ul.errors.freeform-errors {
  color: red;
  padding-left: 0;
  margin-bottom: 10px;
  list-style-type: none;
}

.freeform-form-has-errors {
  display: none;
}

.form-group input[type=email] {
  float: none !important;
}

ul.errors li {
  padding-left: 0 !important;
  background: none !important;
}