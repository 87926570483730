.c--ctas-wrapper {
  display: flex;

  p:first-child {
    margin-right: 20px;
    @media screen and (max-width: 650px) {
      margin-right: 0;

    }
  }

  @media screen and (max-width: 768px) {
    width: 100%;
    justify-content: center;
    flex-wrap: wrap;
  }
  @media screen and (max-width: 650px) {
    flex-direction: column;
    padding-left: 10px;
    padding-right: 10px;
    p a {
      display: inline-block;
    }
  }
}

.c--ctas__button-alt {
  background: rgba(228, 234, 249, 0.5);
  color: #0231B5;

  &:hover {
    background: #1139F8;
    color: white;
  }
}