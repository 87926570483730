@import "../variables.less";

.section.featured-item {
  .js-modal-btn {
    cursor: pointer;
  }

  font-size: 18px;
  line-height: 27px;

  .container {
    margin-bottom: 220px;
  }

  .image-block.first {
    padding-left: 50px;
    text-align: right;

    img.threed {
      transform: matrix3d(0.94, 0, 0.34, -0.0002, 0, 1, 0, 0, -0.34, 0, 0.94, 0, 1, 0, 10, 1) translateX(-10px);
      perspective: 200px;
      transition: all .2s ease;
      box-shadow: 0px 10px 50px rgba(0, 0, 0, 0.1);
      border-radius: 8px;
    }

    .m({
      float: none;
      padding-left: 0;
      text-align: center;
      //position: absolute;
      //top: 30px;
      width: 100%;
      left: 0;
      //z-index: -1;
      margin-bottom: 20px;
      img {
        float: left;
      }
    });
  }

  .content {
    display: flex;
    align-items: center;

    &.left {
      flex-direction: row-reverse;
      .m({
        flex-wrap: wrap;
      });
    }


    .m-up-only({
      position: relative;
      z-index: 2;
    });

    p {
      margin: 0 0 20px;

      &:last-of-type {
        margin: 0 0 0;
      }
    }

    ul {
      margin: 0 0 20px;
      padding: 0;
      list-style: none;

      li {
        padding-left: 20px;
        background: url(../assets/list-item.svg) no-repeat;
        background-position: 0px 9px;
        margin-bottom: 10px;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    .content-block {
      padding-top: 40px;
      .m({
        padding-top: 0;
      });
    }
  }

  &.featured-item-content-type {
    .section-header {
      margin-bottom: 50px;
    }

    .content {
      p {
        margin: 0 0 50px;

        &:last-of-type {
          margin: 0 0 0;
        }
      }
    }
  }

  .m({
    .container {
      position: relative;
      padding-top: 272px;
      background: url(../assets/header-fade.svg) no-repeat;
      background-position: center top 33px;
      background-size: 100% auto;
      margin: 0;
    }
    background: #fff;
    padding: 50px 0 80px;
    .wave-top,
    .wave-bottom {
      display: none;
    }
    .section-header {
      text-align: left;

      h2.title {
        font-size: 24px;
        line-height: 29px;
        margin-bottom: 10px;
      }

      h3.sub-heading {
      }

      margin-bottom: 15px;
    }
  });
}