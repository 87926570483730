@import "../variables.less";

.section.customer-stories {
  h2 {
    max-width: 100% !important;
    width: 100% !important;
  }

  .m({
    .container {
      padding-bottom: 0;
    }
  });
}

#customer-stories {
  .slick-track {
    display: flex !important;
    padding: 50px 0;
    .m({
      padding: 0 0 50px;
    });
  }

  .slick-slide {
    height: inherit !important;
  }

  .slick-slide {
    width: 520px;
    padding: 0 10px;
    outline: 0;

    .customer-story {
      display: table;
      width: 100%;
      height: 100%;

      .wrap {
        display: table-cell;
        vertical-align: middle;
        position: relative;
      }
    }

    .customer-story {
      .customer-story-wrapper {
        height: 259px;
      }
    }

    .m({
      width: 245px;
      .customer-story {
        .customer-story-wrapper {
          height: 448px;
        }
      }
    });
  }

  .slick-current {
    width: 550px;

    img {
      width: 100%;
    }

    .customer-story {
      .content {
        left: 190px;
        .m({
          left: 14px;
        });
      }

      .customer-story-wrapper {
        height: 290px;
      }
    }

    .m({
      width: 245px;
      .customer-story {
        .customer-story-wrapper {
          height: 539px;
        }
      }
    });
  }

  .customer-story {
    .customer-story-wrapper {
      border: 1px solid #EBEEFE;
      box-sizing: border-box;
      box-shadow: 0px 18px 24px #D9DEF8, -9px -18px 18px rgba(236, 239, 254, 0.25);
      border-radius: 6px;
      background-position: center !important;
      background-size: cover !important;
    }

    .content {
      padding: 20px 20px 20px 0;
      position: absolute;
      bottom: 60px;
      left: 180px;
      font-size: 16px;
      line-height: 20px;
      .m({
        left: 14px;
        font-size: 14px;
        line-height: 18px;
      });

      h3 {
        font-family: @headingfont;
        font-style: normal;
        font-weight: bold;
        font-size: 20px;
        line-height: 24px;
        margin-bottom: 10px;
        color: #092984;
        .m({
          font-size: 18px;
          line-height: 22px;
        });
      }

      p {
        margin: 0 0 20px;

        &:last-of-type {
          margin-bottom: 0;
        }
      }

      .btn.download {
        padding: 2px 10px 3px;
        font-size: 14px;
      }
    }
  }

  .slick-current {
    .customer-story {
      .customer-story-wrapper {
        .content {
          .m({
            bottom: 14px;
          });
        }
      }
    }
  }
}