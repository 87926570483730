@import "../variables.less";
#footer{
	background:#02154A;
	background: linear-gradient(180deg, #000F39 0%, #02154A 100%);
	.top-footer{
		padding: 56px 0;
		overflow:hidden;
		background:url(../assets/footer-dots.svg) no-repeat;
		background-position: bottom 10px right -50px;
		
		.m({
			padding-bottom: 128px;
			background-position: bottom 10px right 10px;
		});
	}
	.bottom-footer{
		padding: 31px 0;
		background: #010B28;
		color:rgba(255, 255, 255, 0.6);
		font-family: @headingfont;
		font-style: normal;
		font-weight: normal;
		font-size: 14px;
		line-height: 17px;
		ul{
			padding: 0;
			margin: 0;
			li{
				display: inline; margin-right: 5px;
				a{ color:rgba(255, 255, 255, 0.6); }
			}
		}
	}
	
	.logo{
		//background:url(../assets/footer-logo.svg) no-repeat center;
		background-size: 190px 32px;
		display:block;
		overflow:hidden;
		//text-indent:-9999px;
		width:190px;
		height:32px;
		
		.m({
			margin-bottom: 18px;
		});
	}
	.footer-metas{
		margin: 0 0 56px;
		.m({
			margin: 0 0 28px;
		});
	}
	.contact-us-btn{
		float:right;
		width: 50%;
		height: 36px;
		background: #FF5955;
		font-family: @headingfont;
		font-style: normal;
		font-weight: bold;
		font-size: 16px;
		line-height: 19px;
		text-align: center;
		letter-spacing: 0.1em;
		text-transform: uppercase;
		border: 0;
		text-align: center;
		display: block;
		line-height: 36px;
		box-shadow:none;
		
		.m({
			width:100%;
		});
	}
	.m({
		.grid__item.small--one-half{ margin-bottom: 27px; }
	});
	.footer-menu{
		margin-bottom: 27px;
		&:last-of-type{ margin-bottom: 0; }
		&.social-media{
			padding-top: 27px;
		}
		h2{
			font-family: @headingfont;
			font-style: normal;
			font-weight: bold;
			font-size: 16px;
			line-height: 27px;
			text-decoration:none;
			color: #FFFFFF;
			margin:0;
			text-transform:uppercase;
		}
		ul{
			margin:0;
			padding:0;
			li{
				line-height: 27px;
				a{
					font-family: @headingfont;
					font-style: normal;
					font-weight: normal;
					font-size: 16px;
					line-height: 27px;
					text-decoration:none;
					color: #FFFFFF;
					&:hover{ color: #FF5955; }
					
					.m({
						font-size: 14px;
					});
				}
				&.active{
					a{ color: #FF5955; }
				}
			}
		}
	}
	
	.m({
		.last-menu{
			margin-top:-54px;
			
			.footer-menu.social-media{
				li{ float: left; width: 50%; }
			}
		}
	});
}