@btn-background : #0231B5;
@nav-primary-color : #0231B5;
@btn-border-radius: 6px;

@headingfont : 'Roboto' ;
@bodyfont :'Source Sans Pro';
@subheadingfont : 'Roboto Slab';
@headingcolor : #092984;
@bodycolor : #02154A;
@subheadingcolor : #02154A;
@buttonhovercolor: #1139F8;
@buttonactivecolor: #092984;
@submenucolor : #0231B5;
@submenuhovercolor :#FF5955 ;
@inputtextcolor: #0231B5;
@inputtextfont: 'Roboto' ;

.no-padding-no-margin(){
	margin:0;
	padding:0;
}
@breakpoint-smx: 480px;
@breakpoint-sm: 540px;
@breakpoint-m : 768px;
@breakpoint-m-up : 769px;
@breakpoint-t : 1024px;
@breakpoint-cb : 1022px;
@breakpoint-l : 1220px;

.clearfix(){
	clear:both; content:""; display: table;
}
.clearfix:after{
	.clearfix();
}

.sm(@content) {
    @media only screen and (max-width: @breakpoint-sm) {
        @content(); 
    }
}
.m(@content) {
    @media only screen and (max-width: @breakpoint-m) {
        @content(); 
    }
}
.t(@content) {
    @media only screen and (max-width: @breakpoint-t) {
        @content(); 
    }
}
.l(@content) {
    @media only screen and (max-width: @breakpoint-l) {
        @content(); 
    }
}
.cb(@content) {
    @media only screen and (max-width: @breakpoint-cb) {
        @content(); 
    }
}
.ls-only(@content) {
    @media only screen and (min-width: @breakpoint-t) {
        @content(); 
    }
}

.m-up-only(@content) {
    @media only screen and (min-width: @breakpoint-m-up) {
        @content(); 
    }
}

