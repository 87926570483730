@import "variables.less";
@import "skeleton.less";
@import "typography.less";
@import "grid.less";
@import "atoms/buttons.less";
@import "atoms/pill.less";
@import "atoms/carousel.less";
@import "atoms/tabs.less";
@import "waves/waves.less";

@import "partials/header.less";
@import "partials/navigation.less";
@import "partials/footer.less";
@import "partials/blog.less";
@import "partials/banner.less";
@import "partials/index-banner.less";
@import "partials/index-element-block.less";
@import "partials/index-call-to-action.less";
@import "partials/index-wave-tabs.less";
@import "partials/client-icons.less";
@import "partials/featured-item.less";
@import "partials/overview.less";
@import "partials/list-grid.less";
@import "partials/customer-stories.less";
@import "partials/related-blogs.less";
@import "partials/faq.less";
@import "partials/2-column-content-image";
@import "partials/three-column-grid";
@import "partials/team";
@import "partials/contact-form";
@import "partials/download-form";
@import "partials/map-address";
@import "partials/slick";
@import "partials/c--texte-seo";
@import "partials/c--grosblocs";

@import "partials/c--cards";
@import "partials/c--ctas";
@import "partials/testimonials-videos";
@import "cookie-consent";
@import "announcement";

@import "external/flickity";

@keyframes modal-video {
  from {
    opacity: 0
  }
  to {
    opacity: 1
  }
}

@keyframes modal-video-inner {
  from {
    transform: translate(0, 100px)
  }
  to {
    transform: translate(0, 0)
  }
}

.modal-video {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000000;
  cursor: pointer;
  opacity: 1;
  animation-timing-function: ease-out;
  animation-duration: .3s;
  animation-name: modal-video;
  -webkit-transition: opacity .3s ease-out;
  -moz-transition: opacity .3s ease-out;
  -ms-transition: opacity .3s ease-out;
  -o-transition: opacity .3s ease-out;
  transition: opacity .3s ease-out
}

.modal-video-close {
  opacity: 0
}

.modal-video-close .modal-video-movie-wrap {
  -webkit-transform: translate(0, 100px);
  -moz-transform: translate(0, 100px);
  -ms-transform: translate(0, 100px);
  -o-transform: translate(0, 100px);
  transform: translate(0, 100px)
}

.modal-video-body {
  max-width: 940px;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  display: table
}

.modal-video-inner {
  display: table-cell;
  vertical-align: middle;
  width: 100%;
  height: 100%
}

.modal-video-movie-wrap {
  width: 100%;
  height: 0;
  position: relative;
  padding-bottom: 56.25%;
  background-color: #333;
  animation-timing-function: ease-out;
  animation-duration: .3s;
  animation-name: modal-video-inner;
  -webkit-transform: translate(0, 0);
  -moz-transform: translate(0, 0);
  -ms-transform: translate(0, 0);
  -o-transform: translate(0, 0);
  transform: translate(0, 0);
  -webkit-transition: -webkit-transform .3s ease-out;
  -moz-transition: -moz-transform .3s ease-out;
  -ms-transition: -ms-transform .3s ease-out;
  -o-transition: -o-transform .3s ease-out;
  transition: transform .3s ease-out
}

.modal-video-movie-wrap iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%
}

.modal-video-close-btn {
  position: absolute;
  z-index: 2;
  top: -35px;
  right: -35px;
  display: inline-block;
  width: 35px;
  height: 35px;
  overflow: hidden;
  border: none;
  background: transparent
}

.modal-video-close-btn:before {
  transform: rotate(45deg)
}

.modal-video-close-btn:after {
  transform: rotate(-45deg)
}

.modal-video-close-btn:before, .modal-video-close-btn:after {
  content: '';
  position: absolute;
  height: 2px;
  width: 100%;
  top: 50%;
  left: 0;
  margin-top: -1px;
  background: #fff;
  border-radius: 5px;
  margin-top: -6px
}

.image-block {
  position: relative;

}

.playbutton {
  position: absolute;
  width: 74px;
  top: 50%;
  left: 50%;
  margin-left: -38px;
  margin-top: -38px;
  box-sizing: border-box;
  pointer-events: none;
  transition: all .1s ease;
  will-change: border-width;
  cursor: pointer;
  color: white;
  font-size: 43px;
  padding: 18px 24px;
  background: rgba(0, 0, 0, 0.8);
  border-radius: 8px;
  line-height: 38px;
  height: 74px;
}

section.buttonsection {
  padding: 36px 0;
  z-index: 9999999;
  position: relative;
  text-align: center
}

.captchatoc {
  font-size: 11px;
  margin-top: 9px;

  a {
    color: white;
  }

  opacity: 0.8;
}

.grecaptcha-badge {
  visibility: hidden;
}

.elc-primary-heading {
  font-size: 18px;
  line-height: 27px;
}

.section.center-illustration {

  // margin-bottom: 48px;
  margin-bottom: 200px;

}


/* Add some top padding to the page content to prevent sudden quick movement (as the header gets a new position at the top of the page (position:fixed and top:0) */
.page-container {
  //padding-top: 109px;
  padding-top: 209px;
}


section.iframe iframe {
  border: 0;
}


.mt-10 {
  margin-top: 35px;
}