@import "../variables.less";

.section.contact-form {
  overflow: hidden;

  .container {
    padding-top: 138px;
    padding-bottom: 138px;
    position: relative;
    .m({
      background: url(../assets/header-fade.svg) no-repeat;
      background-position: center top 135px;
      background-size: 100% auto;
      padding-top: 320px;
      text-align: center;

      .form-group textarea.input-textarea,
      .form-group input.input-text,
      .form-group.submit-action input {
        width: 100%;
      }
    });

    .image-block {
      position: absolute;
      left: 50%;
      margin-left: 113px;
      height: 95%;
      top: 50%;
      transform: translateY(-50%);

      img {
        height: 100%;
        width: auto;
      }

      .m({
        width: 416px;
        left: 0;
        margin-left: 0;
        transform: 0;
        z-index: -1;
        img {
          height: auto;
          width: 100%;
        }
      });
    }

    .form-group {
      textarea.input-textarea,
      input.input-text {
        float: none;
        margin-bottom: 8px;
      }

      &.submit-action {
        input {
          float: right;
        }
      }
    }
  }
}