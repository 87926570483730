@import "../variables.less";
.section.map-address{
	.container{
		margin-bottom:0;
		padding-top: 10px;
		padding-bottom: 70px;
		.content{ position:relative; }
		
		.m({ padding:0 0 50px; });
	}
	
	.map-iframe-holder{
		float:right;
		position:relative;
		.m({ float: none; });
	}
	.map-list-holder{
		float:left;
		.m({ float: none; });
	}
	
	.map-address-container{
		width:100%;
		height:520px;
		border: 4px solid #FFFFFF;
		box-sizing: border-box;
		background:#fff;
		box-shadow: 5px 32px 33px rgba(217, 222, 248, 0.43), -9px -18px 18px rgba(236, 239, 254, 0.25);
		iframe{
			border: 0;
			width: 100%;
			height: 100%;
		}
		opacity:0;
		position:absolute;
		z-index:-1;
		top:0;
		right:0;
		transition: opacity 0.3s;
		&.active{ opacity: 1; position:relative; }
		
		.m({ border:0; box-shadow:none; border-radius:0; height: 375px; });
	}
	.map-address-list{
		margin:0;
		padding:0;
		list-style:none;		
		li{
			.map-details{
				display:block; padding-left: 150px;
				position:relative;
				.m({ padding-left: 100px; });
				span.status{
					background:url(../assets/partials/list-unselected.svg) no-repeat;
					display:block;
					width:16px;
					height:16px;
					position:absolute;
					left: 75px;
					top: 50%;
					transform:translate(-50%,-50%);
					.m({ left:50px; });
				}
			}
			&.active{
				.map-details{
					span.status{
						background:url(../assets/partials/list-selected.svg) no-repeat;
					}
				}
			}
			
			padding: 72px 0;
			.m({ padding: 20px 0; });
			
			&:first-child{ padding-top:16px; }
			&:last-child{ padding-bottom:16px; }
			font-size: 18px;
			line-height: 27px;
			h3.title{
				font-family: Roboto Slab;
				font-style: normal;
				font-weight: normal;
				font-size: 20px;
				line-height: 26px;
				margin:0;
				padding: 9px 0;
			}
		}
	}
}