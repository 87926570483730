.summary {
  text-align: center;

}

.tagging {
  color: #FF5955;
  display: inline-block;
  margin: 0 9px;
  text-decoration: none;
}

.blogcatnav {
  text-align: center;
  list-style: none;
  margin-top: 265px;

  li {
    display: inline-block;
    margin: 0 18px;

    a {
      font-weight: 400;
      font-size: 16px;
      line-height: 20px;
      padding: 0 3px 9px;
      color: #092984;
      text-decoration: none;
      border-bottom: 4px solid #fff;


      &.active {
        font-weight: 700;
        border-bottom: 4px solid #FF5955;
      }
    }


  }
}

article {
  text-align: justify;
  font-size: 16px;
  line-height: 27px;


  .toc {
    list-style-position: inside;
    list-style: none;
    padding: 0;
    margin-bottom: 54px;

    & > li > a {
      font-weight: 700;
    }

    ul {
      list-style-position: inside;
      list-style: none;
    }

    li a {
      background: #FFFFFF;
      border: 1px solid #EBEEFE;
      box-sizing: border-box;
      display: block;
      /* PanelNormal */

      box-shadow: 4px 9px 18px #ECEFFE;
      box-shadow: 0px 2px 4px rgba(2, 49, 181, 0.23), 0px -2px 2px rgba(255, 255, 255, 0.5);
      border-radius: 6px;
      padding: 9px;
      margin: 9px 0;
      transition: all 0.3s;
      // a {
      text-decoration: none;
      color: #02154A;
      //}
      &:hover {
        box-shadow: 0px 5px 9px rgba(2, 49, 181, 0.34), 0px -2px 2px rgba(255, 255, 255, 0.5);
      }

      &:active {
        box-shadow: 0px 1px 1px rgba(2, 49, 181, 0.34), 0px -2px 2px rgba(255, 255, 255, 0.5);
      }
    }
  }

  p:first-child {
    font-size: 18px;
    line-height: 31px
  }

  h2, h3, h4 {
    font-family: @headingfont;
    color: #0231B5;
    font-weight: 400;
    margin-top: 36px;
  }

  a {
    font-weight: bold;
    color: #0231B5;
  }

  h2 {
    font-size: 36px;
    margin-top: 54px;
    line-height: 116%;
  }

  h3 {
    font-size: 26px;
  }

  h4 {
    font-size: 20px;
  }

  blockquote {
    font-family: @subheadingfont;
    text-align: center;
    padding: 0;
    font-size: 27px;
    line-height: 150%;
    color: #0231B5;
    margin: 36px 00px;
  }

  figure {
    margin: 36px 0px;

    img {
      max-idth: 100%;
      height: auto;
      display: block;
      margin: 0 auto;
    }
  }

  p {
    font-size: 16px;
    line-height: 27px;
  }
}

#bloglogo {
  margin-top: 42px;
  margin-left: -27px;
}

ul.pagination {
  text-align: center;
  margin-bottom: 100px;
  margin-left: 0;
  padding: 0;

  li {
    display: inline-block;
    margin: 0 9px;
    @media only screen and (max-width: 768px) {
      margin: 0 5px;

    }

    a {

      min-width: 47px;
      padding: 0 18px;
      height: 44px;
      line-height: 44px;
      background: #FFFFFF;
      display: inline-block;

      box-shadow: 0px 2px 4px rgba(2, 49, 181, 0.23), 0px -2px 2px rgba(255, 255, 255, 0.5);
      border-radius: 8px;
      transition: all 0.3s;

      @media only screen and (max-width: 768px) {
        min-width: 20px;
        padding: 0 9px;

      }

      &:hover {
        box-shadow: 0px 5px 9px rgba(2, 49, 181, 0.34), 0px -2px 2px rgba(255, 255, 255, 0.5);
      }

      &:active, &.active {
        box-shadow: 0px 1px 1px rgba(2, 49, 181, 0.34), 0px -2px 2px rgba(255, 255, 255, 0.5);
      }


      color: #042FF8;
      text-decoration: none;
    }

    &.active a {
      color: #FF5955;
    }
  }
}


.newsletterform.wave-section {
  margin-top: 130px;
  padding-top: 48px;

  .container {
    padding-top: 0;
  }

  form {
    padding-bottom: 50px;
    margin-bottom: 280px !important;

    @media only screen and (max-width: 1022px) {
      margin-bottom: 50px !important;

    }
  }
}

.page-container .section.blog_postheader {
  margin-bottom: 80px !important;
  margin-top: -109px;
  padding-top: 139px !important;
  text-align: center;
  padding-bottom: 56px;
  background-size: cover;

  &.wave-section::before {
    display: none;
  }

  &.wave-section::after {
    background: url(../assets/whitewavebottom.svg) no-repeat top center !important;
    background-size: 100% auto;
    top: auto;
    bottom: -1px;
    background-size: cover !important;
    display: block !important;
    height: 187px;

  }


}

.section.blog_list {
  padding: 80px 0;

  &.blog_featured {
    margin-bottom: 180px;
    padding-bottom: 0px;
    margin-top: -109px;
    padding-top: 109px !important;
    padding-bottom: 0 !important;

    &.wave-section::before {
      display: none;
    }
  }


  h1 {
    font-weight: 400;
    font-size: 48px;
  }

  h2, h4 {
    font-weight: 400;
    font-size: 20px;
    font-family: @subheadingfont;
  }


  h3 {
    font-weight: 400;
    font-size: 36px;
  }

  p {

    margin-top: 36px;
  }

  img.threed {
    transform: matrix3d(0.94, 0, 0.34, -0.0002, 0, 1, 0, 0, -0.34, 0, 0.94, 0, 1, 0, 10, 1) translateX(-10px);
    transform: matrix3d(0.94, 0, 0.00, -0.0003, 0.00, 0.93, 0.01, 0, 0, -0.01, 1, 0, 0, 0, 0, 1);
    transform-origin: center center -500px;
    perspective: 200px;
    transition: all .2s ease;
    box-shadow: 0px 10px 50px rgba(2, 39, 181, 0.1);
    border-radius: 8px;
  }

  &.even img.threed {
    transform: matrix3d(0.94, 0, 0.00, -0.0003, 0.00, 0.93, 0.01, 0, 0, -0.01, 1, 0, 0, 0, 0, 1);
    transform: matrix3d(0.94, 0, 0.00, 0.0003, 0.00, 0.93, 0.01, 0, 0, -0.01, 1, 0, 0, 0, 0, 1);
    transform-origin: center center -500px;
    perspective: 200px;
    transition: all .2s ease;
    box-shadow: 0px 10px 50px rgba(2, 39, 181, 0.1);
    border-radius: 8px;
  }


}


.c--cta {
  margin-top:30px;
  text-align:center;
  .c--cta__title {
    font-size: 48px;
    line-height: 58px;
    font-family: 'Ultra';
    color: #092984;
    margin-bottom:20px;
    @media screen and (max-width: 768px) {
      font-size: 36px;
      line-height: 44px;
    }
  }

  .c--cta__text {
    margin-bottom:20px;
    font-size: 20px;
    line-height: 26px;
    color: #02154a;
    @media screen and (max-width: 768px) {
      font-size: 18px;
      line-height: 24px;
    }
  }
}