.c--texte-seo {
  max-height: 300px;
  padding-top: 0 !important;
  overflow: hidden;
  position: relative;
  transition: all 500ms ease;

  &.open {
    max-height: 6000px;
  }

  .container {
    padding-top: 20px !important;
  }
}

.c--texte-seo__see-more {
  position: absolute;
  padding-bottom: 10px;
  padding-top: 10px;
  background-color: white;
  text-align: center;
  border: 0;
  bottom: 0;
  box-shadow: 0px -20px 16px 0px rgba(255, 255, 255, 0.54);
  -webkit-box-shadow: 0px -20px 16px 0px rgba(255, 255, 255, 0.54);


  width: 100%;

  &:hover {
    text-decoration: underline;
  }

}