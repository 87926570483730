@import "variables.less";

/* ruda-regular - latin */
// @font-face {
// 	font-family: 'Ruda';
// 	font-style: normal;
// 	font-weight: 400;
// 	src: url('../fonts/ruda-v12-latin-regular.eot'); /* IE9 Compat Modes */
// 	src: local(''),
// 		 url('../fonts/ruda-v12-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
// 		 url('../fonts/ruda-v12-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
// 		 url('../fonts/ruda-v12-latin-regular.woff') format('woff'), /* Modern Browsers */
// 		 url('../fonts/ruda-v12-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
// 		 url('../fonts/ruda-v12-latin-regular.svg#Ruda') format('svg'); /* Legacy iOS */
//   }
  
//   /* ruda-700 - latin */
//   @font-face {
// 	font-family: 'Ruda';
// 	font-style: normal;
// 	font-weight: 700;
// 	src: url('../fonts/ruda-v12-latin-700.eot'); /* IE9 Compat Modes */
// 	src: local(''),
// 		 url('../fonts/ruda-v12-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
// 		 url('../fonts/ruda-v12-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
// 		 url('../fonts/ruda-v12-latin-700.woff') format('woff'), /* Modern Browsers */
// 		 url('../fonts/ruda-v12-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
// 		 url('../fonts/ruda-v12-latin-700.svg#Ruda') format('svg'); /* Legacy iOS */
//   }

  /* roboto-regular - latin */
@font-face {
	font-family: 'Roboto';
	font-style: normal;
	font-weight: 400;
	src: url('../fonts/roboto-v20-latin-regular.eot'); /* IE9 Compat Modes */
	src: local('Roboto'), local('Roboto-Regular'),
		 url('../fonts/roboto-v20-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
		 url('../fonts/roboto-v20-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
		 url('../fonts/roboto-v20-latin-regular.woff') format('woff'), /* Modern Browsers */
		 url('../fonts/roboto-v20-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
		 url('../fonts/roboto-v20-latin-regular.svg#Roboto') format('svg'); /* Legacy iOS */
  }
  
  /* roboto-500 - latin */
  @font-face {
	font-family: 'Roboto';
	font-style: normal;
	font-weight: 500;
	src: url('../fonts/roboto-v20-latin-500.eot'); /* IE9 Compat Modes */
	src: local('Roboto Medium'), local('Roboto-Medium'),
		 url('../fonts/roboto-v20-latin-500.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
		 url('../fonts/roboto-v20-latin-500.woff2') format('woff2'), /* Super Modern Browsers */
		 url('../fonts/roboto-v20-latin-500.woff') format('woff'), /* Modern Browsers */
		 url('../fonts/roboto-v20-latin-500.ttf') format('truetype'), /* Safari, Android, iOS */
		 url('../fonts/roboto-v20-latin-500.svg#Roboto') format('svg'); /* Legacy iOS */
  }
  
  /* roboto-700 - latin */
  @font-face {
	font-family: 'Roboto';
	font-style: normal;
	font-weight: 700;
	src: url('../fonts/roboto-v20-latin-700.eot'); /* IE9 Compat Modes */
	src: local('Roboto Bold'), local('Roboto-Bold'),
		 url('../fonts/roboto-v20-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
		 url('../fonts/roboto-v20-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
		 url('../fonts/roboto-v20-latin-700.woff') format('woff'), /* Modern Browsers */
		 url('../fonts/roboto-v20-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
		 url('../fonts/roboto-v20-latin-700.svg#Roboto') format('svg'); /* Legacy iOS */
  }
  

  /* roboto-slab-regular - latin */
@font-face {
	font-family: 'Roboto Slab';
	font-style: normal;
	font-weight: 400;
	src: url('../fonts/roboto-slab-v11-latin-regular.eot'); /* IE9 Compat Modes */
	src: local(''),
		 url('../fonts/roboto-slab-v11-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
		 url('../fonts/roboto-slab-v11-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
		 url('../fonts/roboto-slab-v11-latin-regular.woff') format('woff'), /* Modern Browsers */
		 url('../fonts/roboto-slab-v11-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
		 url('../fonts/roboto-slab-v11-latin-regular.svg#RobotoSlab') format('svg'); /* Legacy iOS */
  }
  
  /* source-sans-pro-regular - latin */
@font-face {
	font-family: 'Source Sans Pro';
	font-style: normal;
	font-weight: 400;
	src: url('../fonts/source-sans-pro-v13-latin-regular.eot'); /* IE9 Compat Modes */
	src: local('Source Sans Pro Regular'), local('SourceSansPro-Regular'),
		 url('../fonts/source-sans-pro-v13-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
		 url('../fonts/source-sans-pro-v13-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
		 url('../fonts/source-sans-pro-v13-latin-regular.woff') format('woff'), /* Modern Browsers */
		 url('../fonts/source-sans-pro-v13-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
		 url('../fonts/source-sans-pro-v13-latin-regular.svg#SourceSansPro') format('svg'); /* Legacy iOS */
  }
  
  /* source-sans-pro-italic - latin */
  @font-face {
	font-family: 'Source Sans Pro';
	font-style: italic;
	font-weight: 400;
	src: url('../fonts/source-sans-pro-v13-latin-italic.eot'); /* IE9 Compat Modes */
	src: local('Source Sans Pro Italic'), local('SourceSansPro-Italic'),
		 url('../fonts/source-sans-pro-v13-latin-italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
		 url('../fonts/source-sans-pro-v13-latin-italic.woff2') format('woff2'), /* Super Modern Browsers */
		 url('../fonts/source-sans-pro-v13-latin-italic.woff') format('woff'), /* Modern Browsers */
		 url('../fonts/source-sans-pro-v13-latin-italic.ttf') format('truetype'), /* Safari, Android, iOS */
		 url('../fonts/source-sans-pro-v13-latin-italic.svg#SourceSansPro') format('svg'); /* Legacy iOS */
  }
  
  /* source-sans-pro-700 - latin */
  @font-face {
	font-family: 'Source Sans Pro';
	font-style: normal;
	font-weight: 700;
	src: url('../fonts/source-sans-pro-v13-latin-700.eot'); /* IE9 Compat Modes */
	src: local('Source Sans Pro Bold'), local('SourceSansPro-Bold'),
		 url('../fonts/source-sans-pro-v13-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
		 url('../fonts/source-sans-pro-v13-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
		 url('../fonts/source-sans-pro-v13-latin-700.woff') format('woff'), /* Modern Browsers */
		 url('../fonts/source-sans-pro-v13-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
		 url('../fonts/source-sans-pro-v13-latin-700.svg#SourceSansPro') format('svg'); /* Legacy iOS */
  }
  
/*================ Headings ================*/
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0 0 20px;
  line-height: 1.2;
  font-family: @headingfont;
  font-weight: 400;
  a {
    color: inherit;
    text-decoration: none;
    font-weight: inherit;
  }
}


p a {
	color:inherit;
}
ul a {
	color:inherit;
}
h1,h2,h3,h4,h5,h6{color:@headingcolor;}

.sub-heading{
	font-family: @subheadingfont;
	font-style: normal;
	font-weight: normal;
	font-size: 20px;
	line-height: 26px;
	color:@subheadingcolor;
	.m({
		font-size: 18px;
		line-height: 24px;
	});
}
h2.title,
h1{
	font-style: normal;
	font-family: @headingfont;
	font-weight: normal;
	font-size: 48px;
	line-height: 58px;
	margin: 0 0 20px;
	span{ font-weight: bold; }
	
	.m({
		font-size: 36px;
		line-height: 44px;
	});
	
	&.sm-title{
		font-size:36px;
		line-height:44px;
		.m({
			font-size: 24px;
			line-height: 29px;
		});
	}
}
.title{
	color:@headingcolor;
}
h2{
	font-family: @subheadingfont;
	font-style: normal;
	font-weight: normal;
	font-size: 20px;
	line-height: 26px;
	.m({
		font-size: 18px;
		line-height: 24px;
	});
}
.content{ h2{ font-weight: bold; color:@bodycolor; } }

a,button,.btn{
	cursor: pointer;
}

.section-header.middle {
	text-align: center;
}