.c--testimonials-videos__item {
  display: flex;
  justify-content: space-between;
  padding:20px;

  @media screen and (max-width: 768px) {
    flex-wrap:wrap;
    padding:0px;
  }

}

.c--testimonials-videos__item__right {
  width: calc(50% - 20px);
  box-shadow: 0px 0px 27.4716px rgba(11, 54, 174, 0.1);

  //border: 1px solid rgba(11, 54, 174, 0.2);
  border-radius:10px;
  padding:30px;
  @media screen and (max-width: 768px) {
    width:100%;
  }
}

.c--testimonials-videos__item__left {
  width: calc(50% - 20px);
  @media screen and (max-width: 768px) {
    width:100%;
  }
}


.c--testimonials-videos__item__company {
  letter-spacing: 0.05em;
  text-transform: uppercase;
  font-size: 18px;
  font-weight: bold;
  color: @headingcolor;
}

.c--testimonials-videos__item__name {
  p {
    text-align: right;
    letter-spacing: 0.05em;
    color: @headingcolor;
    text-transform: uppercase;
    font-size: 16px;
    font-weight: bold;
    line-height: 1.2;
  }
}

.c--testimonials-videos__item__testimonial {
  color:black;
  line-height: 1.4;
  margin-top: 5px;
  margin-bottom: 5px;
}

#testimonials-video {
  outline:none;
  .flickity-button {
    background:none;
  }
  .flickity-prev-next-button.next{
    right:-25px;
    @media screen and (max-width: 768px) {
      right:-10px;
    }
  }
  .flickity-prev-next-button.previous{
    left:-25px;
    @media screen and (max-width: 768px) {
      left:-10px;
    }
  }
  .flickity-page-dots .dot {
    background: @headingcolor;
  }
}

.c--testimonials-videos__item__video {
  position: relative;
  cursor:pointer;
  img {
    width:100%;
  }
}

