.c--announcement {
  background-color: #FF5A55;
  display: flex;
  align-items: center;
  color: white;
  font-weight: bold;
  padding-top: 10px;
  padding-bottom: 10px;
justify-content: space-between;
  width: 100%;
  padding-left: 20px;
  padding-right: 20px;
  @media screen and (min-width: 1023px) {
    padding-left: 44px;
    padding-right: 45px;
  }

  @media screen and (max-width:500px) {
    flex-wrap:wrap;
  }
  p {
    font-weight: bold;
  }

  a {
    margin-left: 10px;
    color: #fff;
    background: #0231b5;
    border-radius: 6px;
    text-decoration: none;
    text-align: center;
    padding-left: 10px;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-right: 10px;
    @media screen and (max-width:500px) {
      margin-left: 0px;
      margin-top: 5px;

    }
  }
}

.relative {
  position: relative;
}