@import "../variables.less";
.three-column-grid{	
	padding: 70px 0;
	margin-bottom:100px;
	.m({
		padding: 50px 0;
	});
	.section-header{
		margin-bottom: 60px;
		.m({
			margin-bottom:50px;
		});
	}
	.grid-block{
		.icon-wrap{ margin-bottom:32px; }
		font-size: 16px;
		line-height: 27px;
		margin: 0 auto;
		.m({
			margin-bottom:50px;
		});
		
		h3.col-title{
			font-style: normal;
			font-weight: normal;
			font-size: 20px;
			line-height: 24px;
			text-align: center;
			color: #092984;
			margin: 0 0 8px;
		}
		h4.sub-title{
			font-family: Roboto Slab;
			font-style: normal;
			font-weight: normal;
			font-size: 18px;
			line-height: 24px;
			margin-bottom:15px;
		}
		p{ margin: 0 0 30px; &:last-of-type{ margin: 0 0 0; } }
	}
}