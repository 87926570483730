.slick-prev,
.slick-next{
	width:60px;
	width: 60px;
	height: 28px;
	left: 0px;
	top: 0px;
	outline:0;
	background: linear-gradient(180deg, #FFFFFF 0%, #F1F3FE 100%);
	border: 1px solid #EBEEFE;
	box-sizing: border-box;
	box-shadow: 0px 2px 4px rgba(2, 49, 181, 0.23), 0px -2px 2px rgba(255, 255, 255, 0.5);
	position:relative;
	margin: 0 1px;
	span{
		width: 13px;
		height:11px;
		display:block;
		margin:0 auto;
	}
}
.slick-prev{
	border-radius: 6px 0px 0px 6px;
	span{
		background:url(../assets/carousel-arrow-left.svg) no-repeat center;
	}
	
	&:hover{
		box-shadow: 0px 5px 9px rgba(2, 49, 181, 0.34), 0px -2px 2px rgba(255, 255, 255, 0.5);
	}
	&:active{
		box-shadow: 0px 2px 4px rgba(2, 49, 181, 0.23), 0px -2px 2px rgba(255, 255, 255, 0.5);
	}
}
.slick-next{
	border-radius: 0px 6px 6px 0px;
	span{
		background:url(../assets/carousel-arrow-right.svg) no-repeat center;
	}
	
	&:hover{
		box-shadow: 0px 5px 9px rgba(2, 49, 181, 0.34), 0px -2px 2px rgba(255, 255, 255, 0.5);
	}
	&:active{
		box-shadow: 0px 2px 4px rgba(2, 49, 181, 0.23), 0px -2px 2px rgba(255, 255, 255, 0.5);
	}
}
.carousel-nav{ text-align:center; padding: 0 0 20px; }