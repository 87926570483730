@import "../variables.less";
.section.call-to-action{
	.section-header{
		h2{
			width:754px;
			max-width:100%;
			margin: 0 auto 30px;
			
			.m({
				font-size: 24px;
				line-height: 29px;
				margin-bottom:15px;
			});
		}
		//margin-bottom:100px;
		.m({
			margin-bottom:50px;
		});
	}
	
	.call-to-action-block{
		  padding-left: 90px;
      .m({
        margin-bottom: 0px;
        padding-bottom: 0px;
    });
		.counter{
			width: 70px;
			float: left;
			margin-left: -90px;
			height: 183px;
			border-right:1px solid #FF5955;
			margin-top:7px;
			span{
				font-family: @headingfont;
				font-style: normal;
				font-weight: normal;
				font-size: 44px;
				line-height: 106.07%;
				color: #0231B5;
				display:block;
				text-align: center;
				position: relative;
				top: -7px;
			}
		}
		.content{
			float:left; width:100%;
			font-size: 16px;
			line-height: 27px;
			.m({
				font-size: 14px;
			});
			
			h3{
				color: #0231B5;
				font-style: normal;
				font-weight: normal;
				font-size: 36px;
				line-height: 44px;
				.m({
					font-size: 24px;
					line-height: 29px;
				});
			}
			p{ margin: 0 0 20px; &:last-of-type{ margin-bottom:0;} }
		}
	}
	
	.call-to-action-block{ 
		// margin-bottom: 50px; 
	}
	
	form{
		width: 555px; max-width:100%; text-align: center; margin: 0 auto 0px !important;
		
		.m({
			margin-bottom: 50px;
			.form-group input[type="text"]{ margin-bottom: 12px; }
			.form-group input[type="text"],
			.form-group .input-submit{ width:100%; }
		});
	}
}
