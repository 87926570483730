@import "../variables.less";

.section.faq-section {
  padding: 100px 0 100px;
  .m({
    padding: 50px 0 30px;
  });

  .section-header {
    margin-bottom: 58px;
  }

  .faq-blocks {
    width: 700px;
    max-width: 100%;
    margin: 0 auto;

    .faq-block {
      background: #FFFFFF;
      border: 1px solid #EBEEFE;
      box-sizing: border-box;
      box-shadow: 4px 9px 18px #ECEFFE;
      border-radius: 6px;
      margin-bottom: 16px;

      .question {
        padding: 34px 110px 28px 36px;

        .m({
          padding: 18px 110px 13px 18px;
        });

        font-family: @headingfont;
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 19px;
        color: #092984;
        position: relative;

        .arrow-down {
          position: absolute;
          top: 50%;
          margin-top: -14px;
          right: 36px;
          left: auto;
          .m({
            right: 18px;
          });
        }
      }

      .answer {
        overflow: hidden;
        opacity: 0;
        transition: opacity 0.3s;
        padding: 0;
        max-height: 0;
        font-size: 16px;
        line-height: 27px;

        p {
          margin: 0 0 50px;

          &:last-of-type {
            margin: 0 0 0;
          }
        }
      }

      &.active {
        .question {
          border-bottom: 1px solid #EBEEFE;

          .arrow-down {
            &:before {
              transform: rotate(-180deg);
            }
          }
        }

        .content {
          opacity: 1;
          margin: 28px 36px;
          padding: 0;
          max-height: 1000px;
          .m({
            padding: 0;
            margin: 18px;
          });
        }
      }
    }
  }
}