.c--cards {
  padding-top: 80px;
}

.c--cards__items {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  text-align: center;

  .c--cards--alt & {
    text-align: left;

    h3 {
      //font-weight: 500;
      text-align: center;
    }

    ul {
      li {
        color: #0B36AE;
        font-weight: bold;
      }

      margin-top: 0;
    }

    p {
      line-height: 1.5;
    }
  }
}

.c--cards__items__item {
  margin-right: 10px;
  margin-left: 10px;
  width: calc(25% - 20px);
  border: 1px solid rgba(11, 54, 174, 0.2);

  .c--cards__shadow & {
    border: 0;
    box-shadow: 0px 0px 27.4716px rgba(11, 54, 174, 0.2);
  }

  display: flex;
  flex-direction: column;
  margin-bottom: 30px;
  @media screen and (max-width: 1040px) {
    width: calc(50% - 20px);
  }
  @media screen and (max-width: 600px) {
    width: 100%;
  }

  h3 {
    //flex-grow: 1;
    .c--cards--alt & {
      text-transform: uppercase;
      margin-bottom: 24px;
    }
  }
}

.c--cards__content {
  padding: 20px;
  display: flex;
  flex-direction: column;
  flex-grow: 1;

  .c--cards--alt & {
    padding: 24px;
    padding-top: 18px;
  }
}

.c--cards__cta {
  text-transform: uppercase;
  font-size: 13px;
  background: #ED655D;
  border-radius: 13.1797px;
  padding: 4px 12px;
  text-decoration: none;
  display: inline-block;
  color: white;
}

.c--cards__content__main {
  flex-grow: 1;
  font-size: 15px;

  p:first-child {
    margin-top: 0;
  }
}

.c--cards__container {
  max-width: 1280px;
  margin-left: auto;
  padding-left: 20px;
  padding-right: 20px;
  margin-right: auto;
}

h2.c--cards__title {
  //text-transform: uppercase;
  //font-size: 28px;
  //border-bottom: 2px solid;
  //padding-bottom: 10px;
  //display: inline-block;
  //margin-bottom: 30px;
  text-align: center;
  font-weight: bold;
  margin-bottom: 40px;
}
