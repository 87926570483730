@import "../variables.less";

.team-section-block {
  padding: 0px 0;
  margin-bottom: 80px;
  font-size: 16px;
  line-height: 27px;

  .section-header {
    margin-bottom: 50px;
  }

  .team-image {
    img {
      width: 138px;
      height: 138px;
      padding: 0;
      background: #fff;
      border-radius: 138px;
      border: 3px solid #FFFFFF;
      box-sizing: border-box;
      box-shadow: 0px 2px 4px rgba(2, 49, 181, 0.23), 0px -2px 2px rgba(255, 255, 255, 0.5);

      transform: scale(1);
      transition: all 0.3s;
      .m({
        width: 80px;
        height: 80px;
      });
    }

    a img:hover {
      box-shadow: 0px 5px 9px rgba(2, 49, 181, 0.34), 0px -2px 2px rgba(255, 255, 255, 0.5);
      transform: scale(1.05);


    }

    margin-bottom: 24px;
    &.team-image--noshadow {
      img {
        box-shadow: none;
        border:0;
        border-radius:0px;
        height:auto;
      }
    }
  }

  h3.title {
    font-size: 20px;
    line-height: 24px;
    color: #092984;
    margin: 0 0 8px;
    margin-top: 24px;
  }

  h4.subtitle {
    margin: 0 0 8px;
    font-size: 18px;
    line-height: 24px;
  }

  .teamlink {
    color: @buttonhovercolor;
    display: block;
  }

  .team-block {
    margin-bottom: 50px;
a {
  color:inherit;
}
    p {
      margin: 0 0 20px;

      &:last-of-type {
        margin: 0 0 0;
      }
    }

    .m({
      margin-bottom: 30px;
      text-align: left !important;
      .clearfix();
      padding-left: 100px;
      .team-image {
        float: left;
        width: 100px;
        margin-left: -100px;
      }
    });
  }

  .grid {
    .grid__item:nth-child(3n+1) {
      clear: both;
    }
  }
}
