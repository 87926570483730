.c--grosblocs {
  display: flex;
  flex-wrap: wrap;

}

.c--grosblocs__item {
  width: 25%;
  padding-top: 25%;
  position: relative;

  @media screen and (max-width: 1000px) {
    width: 50%;
    padding-top: 50%;
  }
  @media screen and (max-width: 500px) {
    width: 100%;
    padding-top: 100%;
  }
  &.c--grosblocs__item--5 {
    width:20%;
    padding-top: 20%;
    @media screen and (max-width: 1000px) {
      width: 50%;
      padding-top: 50%;
    }
    @media screen and (max-width: 500px) {
      width: 100%;
      padding-top: 100%;
    }
  }
}

.c--grosblocs__item__inner {
  padding: 50px 30px;
  display: flex;
  align-items: flex-end;
  position: absolute;
  background-color: rgba(0, 0, 0, 0.4);
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  text-decoration: none;
  color: white;
  transition: background-color 300ms ease;

  &:hover {
    background-color: rgba(0, 0, 0, 0.5);

    .c--grosblocs__item__inner__text {
      padding-left: 10px;
    }
  }
}

.c--grosblocs__item__inner__text {
  border-left: 2px solid #ff5955;
  font-weight: 700;
  font-size: 22px;
  text-transform: uppercase;
  display: block;

  line-height: 1.3;
  letter-spacing: 0.05em;
  padding-left: 20px;
  width: 100%;
  transition: padding-left 300ms ease;

}

.c--grosblocs__item__img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -1;
}