@import "../variables.less";
.section.overview{
	.container{
		margin-bottom: 220px;
		.m({ margin-bottom: 20px; });
		h2{font-family: @headingfont;}
	}
	.section-header{ margin-bottom: 50px; }
	.content{
		
		font-size:18px;
		line-height:27px;
		.m-up-only({
			position:relative;
			z-index:2;
		});
		p{ margin: 0 0 50px; &:last-of-type{ margin: 0 0 0; } }
		ul{
			margin:0 0 20px;
			padding:0;
			list-style:none;
			li{
				padding-left: 20px;
				background:url(../assets/list-item.svg) no-repeat;
				background-position: 0px 9px;
				margin-bottom: 10px;
				&:last-child{ margin-bottom: 0; }
			}
		}
	}
	
	
	.m({
		padding: 0px 0 0px;
		.section-header{
			h2{
				font-size: 24px;
				line-height: 29px;
				margin-bottom:10px;
			}
			h3.sub-heading{}
			margin-bottom: 15px;
		}
	});
}