@import "../variables.less";

.section {
  &.two-column-image {

    padding: 50px 0;
    margin-bottom: 40px;

    &.no-padding {
      padding: 0 !important;
      .m({
        padding-bottom: 20px !important;
      });
    }

    font-size: 18px;
    line-height: 27px;

    .section-header {
      text-align: left;

      h2 {
        font-family: @headingfont;
        color: #092984;
      }
    }

    .content {
      display: flex;
      align-items: center;

      &.left {
        flex-direction: row-reverse;
      }

      & > div {
        flex: 0 0 auto;
      }
    }

    .image-block {
      padding-right: 50px;

      img.threed {
        transform: matrix3d(0.94, 0, 0.34, 0.0002, 0, 1, 0, 0, -0.34, 0, 0.94, 0, 1, 0, 10, 1) translateX(30px);

        perspective: 200px;
        transition: all .2s ease;
        box-shadow: 0px 10px 50px rgba(2, 39, 181, 0.1);
        border-radius: 8px;
      }
    }

    .image-block.first {
      padding-right: 0;
      //padding-left: 50px;
      //padding-top:5%;
      text-align: right;

      img.threed {
        transform: matrix3d(0.94, 0, 0.34, -0.0002, 0, 1, 0, 0, -0.34, 0, 0.94, 0, 1, 0, 10, 1) translateX(-10px);

        perspective: 200px;
        transition: all .2s ease;
        box-shadow: 0px 10px 50px rgba(2, 39, 181, 0.1);
        border-radius: 8px;
      }
    }

    .image-block {
      &, &.first {
        .m({
          float: none;
          padding-top: 0;
          padding-left: 0;
          text-align: center;
          //position: absolute;
          //top: 30px;
          width: 100%;
          left: 0;
          z-index: -1;
          //img{ float:left; }
        });
      }
    }

    .content {
      .m-up-only({
        position: relative;
        z-index: 2;
      });

      p {
        margin: 0 0 20px;

        &:last-of-type {
          margin: 0 0 0;
        }
      }

      ul {
        margin: 0 0 30px;
        padding: 0;
        list-style: none;

        li {
          padding-left: 20px;
          background: url(../assets/list-item.svg) no-repeat;
          background-position: 0px 9px;
          margin-bottom: 10px;

          &:last-child {
            margin-bottom: 0;
          }
        }
      }

      .content-block {
        .m({
          padding-top: 0;
          width: 100%;
        });
      }

      .m({
        display: block;
      });

    }

    .m({
      .container {
        padding: 0;
      }
      .container .content-block {
        position: relative;
        padding-top: 20px;
        padding-left: 18px;
        padding-right: 18px;
        //background: url(../assets/header-fade.svg) no-repeat;
        background-position: center top 33px;
        background-size: 100% auto;
        margin: 0;
      }
      //background:#fff;
      padding: 50px 0 80px;
      margin-bottom: 30px;
      overflow: hidden;
      .wave-top,
      .wave-bottom,
      .wave-2-top,
      .wave-2-bottom {
        display: none;
      }
      .section-header {
        text-align: left;

        h2.title {
          font-size: 24px;
          line-height: 29px;
          margin-bottom: 10px;
        }

        h3.sub-heading {
        }

        margin-bottom: 15px;
      }
    });
  }
}
