@import "../variables.less";

.bluewave {
  background: rgba(228, 234, 249, 0.5);
  padding: 36px 0px 36px;
}

.darkbluewave {
  background: rgba(2, 49, 181, 1);
  padding: 36px 0px 36px;
  color: white;

  a {
    color: white !important;
  }

  .faq-blocks .faq-block {
    box-shadow: 0px 2px 17px 0px #ffffff99 !important;
  }

  .faq-blocks .faq-block.active .content {
    color: rgba(2, 49, 181, 1);
  }

  h1, h2, h3, h4, h5 {
    color: white !important;
  }

  .btn {
    box-shadow: 0 2px 4px rgba(255, 90, 85, 0.7) !important;

    &:hover {
      box-shadow: 0 5px 9px rgba(255, 90, 85, 0.7) !important;
    }
  }
  .btn.btn-white {
    color:  rgba(2, 49, 181, 1) !important;
    box-shadow: 0 2px 4px rgba(255,255,255,.7)!important;
    &:hover {
      color: white !important;
      box-shadow: 0 5px 9px rgba(255, 255, 255, 0.2) !important;
    }
  }

  .tabs {
    border-bottom: 1px solid rgba(255, 255, 255, .35)
  }

  .tabs li a.active {
    color: white !important;
  }

  .tabs li a.active:after {
    background: rgba(2, 49, 181, 1) !important;
  }

  .f--checkbox {
    color: white;
  }

  .slick-slide .related-block {
    //background: rgba(2, 49, 181, 1) !important;
    background-color: transparent !important;
    border: none !important;
    box-shadow: none !important;

  }

  .call-to-action-blocks .call-to-action-block .counter span {
    color: white !important;
  }

  .list-grid-item {
    h1, h2, h3, h4, h5, p {
      color: rgba(2, 49, 181, 1) !important;
    }
  }

}


.wave-section {
  position: relative;
  z-index: 1;
  background: rgba(228, 234, 249, 0.5);
  //margin-bottom: 240px !important;
  //margin-top: 87px;
  padding-top: 2px !important;
  padding: 36px 0px 54px !important;
  margin-bottom: 130px !important;
  .sm({
    margin-bottom: 40px !important;
  });

  &:before {
    pointer-events: none;
    content: "";
    width: 100%;
    display: block;
    position: absolute;
    height: 195px;
    background: url(../assets/wave-top.svg) no-repeat top center;
    background-size: cover;
    bottom: 100%;
    left: 0;
    margin-bottom: -1px;
    z-index: -1;
    .sm({
      margin-bottom: 0;
    });
  }

  &:after {
    pointer-events: none;

    content: "";
    width: 100%;
    display: block;
    position: absolute;
    height: 150px;
    background: url(../assets/wave-bottom.svg) no-repeat bottom center;
    background-size: cover;
    top: 100%;
    left: 0;
    margin-top: -1px;
    z-index: -1;
    .sm({
      margin-top: 0;
    });
  }

  &.wave-2:before {
    background: url(../assets/wave-2-top.svg) no-repeat bottom center;
    background-size: 100% auto;
    height: 500px;
  }

  &.wave-2:after {
    background: url(../assets/wave-2-bottom.svg) no-repeat top center;
    background-size: 100% auto;
    height: 500px;
  }

  .container {
    //	  padding-top:50px;
    //margin-top: 40px;

    //		margin-bottom: 45px;
    .m({
      padding-top: 20px;
      padding-bottom: 20px;
      margin-bottom: 55px;
    });
  }

  .section-header {
    //text-align:center;
    h2 {
      color: #092984;
    }

    .sub-heading {
      margin-bottom: 0;
    }

    margin-bottom: 30px;
  }

  &.wave-2 {
    background: #F1F4FC;
    margin-bottom: 200px;
    margin-top: 50px;
    .m({
      margin-bottom: 140px;
    });
    .sm({
      margin-bottom: 80px;
    });

    h2 {
      width: 780px;
      max-width: 100%;
      display: block;
      margin: 0 auto 20px;
      .m({
        font-size: 24px;
        line-height: 29px;
      });
    }

    h3.sub-heading {
      width: 700px;
      max-width: 100%;
      display: block;
      margin: 0 auto 20px;
    }

    .container {
      margin-bottom: 0;
      position: relative;
      z-index: 3;
    }

    .full-width {
      display: block;
      position: relative;
      margin: -57px auto 0;
      mix-blend-mode: multiply;
    }

    .wave-2-bottom-content {
      width: 320px;
      text-align: center;
      position: absolute;
      bottom: -20px;
      left: 50%;
      transform: translateX(-50%);
      z-index: 1;
      font-family: Source Sans Pro;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 27px;

      p {
        margin: 0 0 20px;

        &:last-of-type {
          margin-bottom: 0;
        }
      }

      .btn-white {
        width: 100%;
        height: auto;
      }

      .m({
        position: relative;
        bottom: 0;
        transform: none;
        left: 0;
        margin: 0 auto;
        width: 100%;
        padding: 0 18px 50px;
      });
    }
  }

  &.custom-wave-block {
    padding: 0 !important;
    margin: 100px 0 250px !important;

    .container {
      padding-top: 70px !important;
      padding-bottom: 20px !important;
    }

    .m({
      margin: 50px 0 70px !important;
    });
  }

  .m({
    &.featured-item {
      &:before, &:after {
        display: none;
      }
    }
  });
}

.bottom-content {
  width: 320px;
  text-align: center;
  position: absolute;
  bottom: -20px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1;
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 27px;

  p {
    margin: 0 0 20px;

    &:last-of-type {
      margin-bottom: 0;
    }
  }

  .btn-white {
    width: 100%;
    height: auto;
  }

  .m({
    position: relative;
    bottom: 0;
    transform: none;
    left: 0;
    margin: 0 auto;
    width: 100%;
    padding: 0 18px 50px;
  });
}

.page-container section:last-of-type {
  .container {
    margin-bottom: 0;
    padding-bottom: 100px;
  }

  &::after {
    display: none;
  }
}
