@import "../variables.less";
@import "../atoms/buttons.less";
#header, #preheader{
	padding: 0;
	width:100%;
	min-height: 65px;
	position: fixed;
	z-index: 999999;
	background: linear-gradient(180deg, #FFFFFF 45.12%, rgba(255, 255, 255, 0) 100%);
	.wrapper{
		padding: 0 264px 0 294px;
	}
	@media only screen and (max-width: 1230px){
		.wrapper{ padding-left: 60px; }
	}
	@media only screen and (max-width: 1022px){
		#nav.toggle{ display: none; }
		#nav_btn{
			position: absolute;
			top: 0;
			right: 0;
			-webkit-transition: all 100ms ease-in-out;
			-moz-transition: all 100ms ease-in-out;
			-ms-transition: all 100ms ease-in-out;
			-o-transition: all 100ms ease-in-out;
			transition: all 100ms ease-in-out;
		}
		#nav_btn{
			display: block;
			float: right;
			padding: 20px 18px;
			cursor: pointer;
			// background: #fff; 
			z-index:4;
		}

		#nav_btn span, #nav_btn span:before, #nav_btn span:after{
			width: 36px;
			height: 6px;
			float: left;
			display: block;
			background: @headingcolor;
			position: relative;
			text-indent: -9000px;
			-webkit-transition: all 100ms ease-in-out;
			-moz-transition: all 100ms ease-in-out;
			-ms-transition: all 100ms ease-in-out;
			-o-transition: all 100ms ease-in-out;
			transition: all 100ms ease-in-out;
			border-radius:6px;
		}
		#nav_btn span{ margin: 8px 0; }
		#nav_btn span:before, #nav_btn span:after{ content: ''; position: absolute; }
		#nav_btn span:before{top: -9px;}
		#nav_btn span:after{bottom: -9px;}
		
		#nav_btn.active{
		//	right: -72px;
		}
		.wrapper{ padding: 0; }
	}
}
#logo{
	width: 217px;
	height: 36px;
	//background: url(images/logo.svg);
	background-size: 217px 36px;
	text-indent: -9000px; 
	display: block;
	margin: 39px 0 0 40px;
	
	
    position: absolute;
    top: 0;
	left: 0;
	
	#colorlogo {
		display: block;
	}
	#circlelogo {
		display: none;
	}
	
	@media only screen and (max-width: 1230px){
		width: 37px;
		height: 37px;
		//background: url(../assets/mobile-logo.svg);
		background-size: 37px 37px;

		#colorlogo {
			display: none;
		}
		#circlelogo {
			display: block;
		}
	}
	@media only screen and (max-width: 1022px){
		top: 13px;
		left: 14px;
		margin:0;
	}
}

.header-btns{
	margin: 38px 46px 38px 40px;
	position:absolute;
	top:0;
	right:0;
	.btn{
		font-family: @headingfont;
		font-style: normal;
		font-weight: bold;
		font-size: 14px;
		line-height: 19px;
		text-align: center;
		letter-spacing: 0.1em;
		text-transform: uppercase;
		display: inline-block;
		padding: 9px 18px;
		@media only screen and (max-width: 768px){
			font-size: 11px;
			}

        span {
			@media only screen and (max-width: 768px){
				display: none;
				}
		}
	}

	.secondary { 
		background: white;
		color: #0231b5;
		font-size: 12px;

margin-right: 18px;
@media only screen and (max-width: 768px){
	font-size: 11px;
	}

	}
	.cb({
		margin:0;
		top: 14px;
		right: 100px;
	});
	.sm({
		right: 50%;
		transform: translatex(50%);
		width: 213px;
	})
}


#header {
	top: 42px;

	.m({
		top: 0px
	});
}
#preheader {
	top: 0px;
	background: white;
	.m({ display: none;});
}