@import "../variables.less";
@import "../atoms/buttons.less";

#main-navigation, #preheader {
  padding-top: 45px;
  padding-bottom: 35px;

  ul {
    .no-padding-no-margin();

    li {
      list-style: none;
      float: left;
      position: relative;

      a {
        font-family: @headingfont;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 19px;
        color: @nav-primary-color;
        text-decoration: none;
        display: block;
      }

    }
  }

  .site-nav {
    &.lang-btns {
      margin-left: 10px;

      li {
        margin: 0 5px;

        a {
          padding: 3px 5px;
        }

        &.active {
          a {
            .blue-btn();
          }
        }
      }
    }

    &.main-nav {
      & > li {
        margin: 0 5px;

        & > a {
          border-bottom: 4px solid transparent;
          padding: 3px 5px;
          position: relative;
          z-index: 2;
        }

        &:hover > a,
        &.active > a {
          border-color: #FF5955;
        }
.sub-menu {
  .site-nav__entry {
    text-transform: initial;
    letter-spacing: initial;
  }
}
        > .sub-menu {
          max-height: 0;
          opacity: 0;
          width: 506px;

          &.small {
            width: 253px;
          }

          position: absolute;
          background: #FFFFFF;
          box-sizing: border-box;
          border-radius: 6px;
          padding: 0;
          transition: opacity 0.3s;
          pointer-events: none;
          left: -21px;
          top: 100%;
          margin-top: -4px;
          z-index: -1;
          @media only screen and (max-width: 1022px) {
            pointer-events: all;

          }

          &.large {
            width: 655px;
          }

          & > .grid {
            opacity: 0;
          }

          ul {
            padding: 17px 0 0;

            &.double-column {
              columns: 2;
              -webkit-columns: 2;
              -moz-columns: 2;
            }

            li {
              float: none;

              a {
                font-size: 14px;
                line-height: 27px;
                color: @submenucolor;
              }

              &:hover > a, &.active > a {
                color: @submenuhovercolor;
                font-weight: bold;

                &:before {
                  content: "";
                  display: block;
                  position: absolute;
                  left: -11px;
                  top: 10px;
                  width: 6px;
                  height: 6px;
                  background: @submenuhovercolor;
                }
              }

            }
          }

          .nav-content {
            padding: 15px 11px;
            margin-bottom: 27px;
            background: #E4EAF9;
            border-radius: 0px 0px 6px 6px;

            h3 {
              font-family: @headingfont;
              font-size: 14px;
              line-height: 27px;
              font-weight: bold;
              margin-bottom: 8px;
            }

            p {
              font-family: @bodyfont;
              font-style: normal;
              font-weight: normal;
              font-size: 12px;
              line-height: 18px;
              color: @bodycolor;
              margin: 0 0 20px
            }

            .icon {
              display: block;
              width: 58px;
              height: 58px;
              float: right;
              background: white;
              border-radius: 29px;
              padding: 9px;
              margin-bottom: 9px;

              img {

              }

              //margin:10px 0 10px 10px;
              //background: url(images/icon-nav-content.svg);
              //background-size:40px;
              &.platform {
                width: 96px;
                height: 103px;
                background-size: 96px;
                background: url(images/icon-nav-platform.svg);
                margin: 0 0 0 25px;
              }
            }

            .btn-group {
              clear: both;
            }

            .btn {
              font-size: 14px;
              line-height: 17px;
              color: #fff;
              padding: 3px;
            }
          }

          .btn-white {
            font-size: 14px;
            line-height: 17px;
            padding: 3px;

            &:hover {
              color: #fff;
            }
          }

          .nav-content-with-button {
            position: relative;

            .nav-view-all-btn {
              position: absolute;
              bottom: 0;
              right: 0;
              width: 100%;
            }

            &.medium-up--two-thirds {
              .nav-view-all-btn {
                max-width: 100%;
                width: 225px;
              }
            }
          }
        }

        .ls-only({
          &:hover > a {
            z-index: 11;
          }
          &:hover > .sub-menu {
            z-index: 10;
            pointer-events: all;
            opacity: 1;
            max-height: 1000px;
            padding: 0 21px 20px 21px;
            box-shadow: 0px 4px 35px rgba(2, 49, 181, 0.15);
            border: 1px solid #ECEFFE;

            & > .grid {
              opacity: 1;
            }

          }
        });
      }
    }

  }

  &:after {
    .clearfix();
  }

  @media only screen and (max-width: 1022px) {

    display: block;
    position: absolute;
    left: 100%;
    z-index: 2;
    // background:#fff;
    padding-top: 23px;
    width: 100%;
    top: 60px;
    transform: translateX(0);
    transition: all 0.5s;

    &.active-state {
      transform: translateX(-100%)
    }

    .site-nav.lang-btns {
      margin-bottom: 18px;
      margin-right: 24px;
    }

    .site-nav.main-nav {
      float: none;
      width: 100%;
      clear: both;
      padding: 0 18px;

      & > li {
        margin: 0 0 20px;
        float: none;

        &:hover > a, &.active > a {
          color: @submenuhovercolor;
          font-weight: bold;
        }

        & > a {
          border-bottom: 0;
          padding: 0;
        }

        & > .sub-menu,
        & > .sub-menu.large {
          max-height: 2000px;
          position: relative;
          opacity: 1;
          width: 100%;
          left: 0;
          box-shadow: none;
          border: 0;
          padding: 0;
          z-index: 10;
          background: transparent;

          & > .grid {
            opacity: 1;

            .grid__item {
              width: 100%;
            }

            .nav-content-with-button {
              display: none;
            }

            ul {
              &, &.double-column {
                columns: 1;
                -webkit-columns: 1;
                -moz-columns: 1;
              }

              & > li {
                &:hover > a, &.active > a {
                  &:before {
                    display: none;
                  }
                }
              }
            }
          }

        }
      }
    }

    .wrapper {
      padding: 0;
    }
  }

}

@media only screen and (max-width: 1022px) {


  #bloglogo {
    display: none;
  }

  .blog_list {
    img {
      display: none;
    }
  }


}

#preheader {
  padding: 8px 0;
  border-bottom: 1px solid #ddd;
  min-height: 40px;
  height: 42px;
  z-index: 99999999;

  .wrapper {
    padding: 0 48px;

    .btn {
      float: right;
    }

    .site-nav.lang-btns li.active a {
      color: #FF5955;
    }

    .btn, .site-nav.lang-btns li a {
      font-size: 11px;
      padding: 4px 8px;
      letter-spacing: 2px;
      line-height: 19px;
      background: white;
      box-shadow: none;
      color: #0231B5;

      &:hover {
        color: #FF5955;
      }
    }

    .btn.secondary.connexion {
      background: white;
      background: #fff;
      border: 1px solid white;
      box-sizing: border-box;
      box-shadow: 0 1px 2px rgba(2, 49, 181, 0.4);
      border-radius: 6px;
      display: inline-block;
      color: #0231b5;
      line-height: 25px;
      padding: 0 14px;
      letter-spacing: 0;
      transition: box-shadow 0.3s;

      &:hover {
        box-shadow: 0 4px 10px rgba(2, 49, 181, 0.4);

      }
    }

  }
}


@media only screen and (max-width: 1022px) {

  #main-navigation {
    position: fixed;
    overflow: auto;
    bottom: 0;
    //top: 85px;
    top:185px;
  }

  #preheader {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    height: 25px;
    min-height: 25px;

    .wrapper {
      padding: 0 9px;

      .site-nav.lang-btns {
        margin-right: 0;
        margin-left: 0;
      }

      .btn, .site-nav.lang-btns li a {
        font-size: 9px;
        padding: 0px 8px;
        letter-spacing: 2px;
        line-height: 8px;
      }

    }
  }

  #header {
    top: 25px;
  }
}

.site-nav__passive {
  text-transform: uppercase;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0.05em;
  margin-bottom: 5px;
  color: #092984;
}

.site-nav__hide-mobile {
  @media screen and (max-width: 768px) {
    display: none;
  }
}

.sub-menu__flex {
  display: flex;
  > li {
    padding-right: 10px;
  }
  //> li:first-child {
  //  padding-right: 5px;
  //}
  //> li:last-child {
  //  padding-left: 5px;
  //}
}
.sub-menu--2 {
  .grid--table {
    display:block;
  }
  .medium-up--one-third {
    width:inherit !important;
  }
}

@media only screen and (max-width: 620px) {
  #main-navigation {
    top:225px;
  }
}