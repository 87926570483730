@import "../variables.less";

.section.client-icons {
  //padding: 110px 0 30px;

  .m({
    //padding: 50px 0 0;
  });

  &.mini {
    .section-header {
      margin: 18px 0 18px;

      h2 {
        font-size: 12px;
        text-transform: uppercase;
        letter-spacing: 2px;
        font-size: 12px;
        text-transform: uppercase;
        letter-spacing: 2px;
        margin: 0;
        line-height: 18px;
        font-family: 'IBM Plex Sans', sans-serif !important;
      }

      h3 {
        display: none
      }
    ;
    }

    figure {
      max-width: 204px;
      margin: 8px 16px !important;

      img {
        height: auto;
      }
    }
  }

  .section-header {
    margin-bottom: 70px;
    .m({
      margin-bottom: 36px;
    });
  }

  h2 strong {
    color: #FF5955;
  }

  .client-icons-block {
    white-space: nowrap;
    //position: absolute;
    //text-align: justify;
    .m({
      text-align: center;
    });

    .client-icon {
      display: inline-block;
      vertical-align: middle;
      margin: 0 10px 70px;
      .m({
        margin-bottom: 40px;
      });
    }

    &:after {
      content: "";
      display: inline-block;
      width: 100%;
      height: 1px;
      .m({
        display: none;
      });
    }
  }
}

.client-icons-block-wrapper {
  overflow: hidden;
  position: relative;
}