@import "../variables.less";
.banner.index-banner{
	//background: url(../assets/header-image.svg);
	background-position: right center;
	background-repeat: no-repeat;
	.l({
	//	background-size: auto 100%;
	});
	.m({
		margin-bottom: 80px;
		background-size: 517px;
		background-position: center top 0px;
		padding: 0;
		.container{
			background: url(../assets/header-fade.svg) no-repeat;
			background-position: center top 33px;
		background-size: 100% auto;
			padding: 221px 0 0;
			text-align:center;
		}
		h1{
			padding: 0 21px;
			margin:0;
		}
		.sub-heading{
			max-width: 100%;
			padding: 22px 15% 27px;
			margin: 0;
		}
		.sub-heading + form{ margin: 0; padding: 0 20px; }
	});
	.sm({
		background-position: center top 0px;
		h1{
			br,br:before{ content: "";display:inline; }
		}
		.sub-heading{
			background: #fff;
		}
	});
}
