@import "../variables.less";

.banner {
  padding: 58px 0 50px;
  height: 500px;
  background-size: contain;
  background-size: contain;
  max-width: 1480px;
  margin: 0 auto;

  .banner-container {
    max-width: 100%;
    width: 720px;

    .sub-heading + form {
      margin-top: 33px;
    }
  }

  .l({
    height: auto;
    padding: 100px 0;
  });


  @media only screen and (min-width: 1200px ) {
    background-size: 50%;
  }

  @media only screen and (min-width: 1024px ) and (max-width: 1199px) {
    background-size: 40%;
  }
  @media only screen and (min-width: 769px )  and (max-width: 1023px) {
    background-size: 30%;
  }

  @media only screen and (max-width: 768px ) {
    background-size: 50%;
    background-position: top center;
  }
}
