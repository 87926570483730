.pill{
	font-family: @headingfont;
	font-style: normal;
	font-weight: bold;
	font-size: 12px;
	line-height: 15px;
	align-items: center;
	text-align: center;
	letter-spacing: 0.1em;
	text-transform: uppercase;

	color: #0B580E;

	text-shadow: 0px 1px 0px rgba(255, 255, 255, 0.27);
	
	background: linear-gradient(180deg, #7AD27D 0%, #5CB660 100%);
	border-radius: 12px;
	display:inline-block;
	position:relative;
	padding: 4px 7px 1px 27px;
	box-shadow: 0px 1px 0px rgba(12, 111, 16, 0.49);
	
	&:before{
		content:"";
		position:absolute;
		background: url(../assets/pill-circle.svg) no-repeat center;
		width:14px;
		height:14px;
		left: 5px;
		top: 3px;
	}
	&:after{
		content:"";
		position:absolute;
		background: url(../assets/pill-star.svg) no-repeat center;
		width:14px;
		height:14px;
		left: 5px;
		top: 3px;
	}
}
.pill-holder{ margin-bottom: 17px; }