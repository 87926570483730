@import "../variables.less";

.blue-btn(){
	color:#fff;
	border-radius:@btn-border-radius;
	background:@btn-background;
	text-decoration:none;	
}
.btn(){
	text-align: center;
	letter-spacing: 0.1em;
	text-transform: uppercase;
	color:#fff;
	border-radius:@btn-border-radius;
	background:@btn-background;
	transition: all 0.3s;
	box-shadow: 0px 2px 4px rgba(2, 49, 181, 0.23), 0px -2px 2px rgba(255, 255, 255, 0.5);
	text-decoration:none;
}
.btn{
	.btn();
	&.btn-white{
		background:transparent;
		color:@btn-background;
		border:1px solid @btn-background;
	}
}
.btn:hover{
	background:@buttonhovercolor;
	box-shadow: 0px 5px 9px rgba(2, 49, 181, 0.34), 0px -2px 2px rgba(255, 255, 255, 0.5);
}
.btn:active{
	background:@buttonactivecolor;
	box-shadow: 0px 1px 1px rgba(2, 49, 181, 0.34), 0px -2px 2px rgba(255, 255, 255, 0.5);
}
.btn-white(){
	width: 213px;
	height: 36px;
	background: #FFFFFF;
	border: 1px solid #0231B5;
	box-sizing: border-box;
	box-shadow: 0px 2px 4px rgba(2, 49, 181, 0.23), 0px -2px 2px rgba(255, 255, 255, 0.5);
	border-radius: 6px;
	display: inline-block;
	color: #0231B5;
	line-height: 36px;
	padding: 0;
	letter-spacing: 0;
	font-size: 16px;
	font-family: @headingfont;
	font-weight: bold;
}
.content{
	.btn-white{
		.btn-white();
	}

	.btn.btn-white:hover{
		color:#fff;
		background:@buttonhovercolor;
		box-shadow: 0px 5px 9px rgba(2, 49, 181, 0.34), 0px -2px 2px rgba(255, 255, 255, 0.5);
	}

}

.btn.call-to-action{
	font-family: @headingfont;
	font-style: normal;
	font-weight: bold;
	font-size: 16px;
	line-height: 19px;
	text-align: center;
	letter-spacing: 0.1em;
	text-transform: uppercase;
	padding:8px 18px;
}



.arrow-up,
.arrow-down{
	width: 60px;
	height: 28px;
	left: 0px;
	top: 0px;
	outline:0;
	background: linear-gradient(180deg, #FFFFFF 0%, #F1F3FE 100%);
	border: 1px solid #EBEEFE;
	box-sizing: border-box;
	box-shadow: 0px 2px 4px rgba(2, 49, 181, 0.23), 0px -2px 2px rgba(255, 255, 255, 0.5);
	border-radius: 6px;
	display:block;
	&:before{
		content:"";
		width: 13px;
		height:11px;
		display:block;
		margin:8px auto;
		-webkit-transition: all 100ms ease-in-out;
		-moz-transition: all 100ms ease-in-out;
		-ms-transition: all 100ms ease-in-out;
		-o-transition: all 100ms ease-in-out;
		transition: all 100ms ease-in-out;
	}
}


.arrow-down{
	&:before{
		background:url(../assets/atom-button-arrow-down.svg) no-repeat center;
	}
	
	&:hover{
		box-shadow: 0px 5px 9px rgba(2, 49, 181, 0.34), 0px -2px 2px rgba(255, 255, 255, 0.5);
	}
	&:active{
		box-shadow: 0px 2px 4px rgba(2, 49, 181, 0.23), 0px -2px 2px rgba(255, 255, 255, 0.5);
	}
}