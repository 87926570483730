@import "../variables.less";
.tabs{
	text-align:center;
	margin:0;
	padding:0;
	border-bottom:1px solid rgba(9, 41, 132, 0.35);
	li{
		display:inline-block;
		float:none;
		vertical-align:top;
		height: auto;
		a{
			font-family: @headingfont;
			font-style: normal;
			font-weight: bold;
			font-size: 14px;
			line-height: 17px;
			display: block;
			text-transform: uppercase;
			align-items: center;
			text-align: center;
			letter-spacing: 0.05em;
			padding:7px 10px 6px;
			color: #8585A7;
			text-decoration:none;
			margin-bottom:-1px;
			&.active{
				top: 0;
				border: 1px solid #a0add2;
				border-bottom: #f1f4fc 1px solid !important;
				border-radius: 6px 6px 0 0px;
				color: #0231B5;
				position:relative;
				&:after{
					content: "";
					display: block;
					width: 100%;
					height: 8px;
					background: #f1f4fc;
					position: absolute;
					left: 0;
					bottom: -4px;
				}
			}
		}
	}
	.m({
		display:table;
		width:100%;
		li{
			display: table-cell;			
		}
	});
	.sm({ li{ a{ min-height: 65px; display:flex; } } });
}
.tab-content{
	.tab-container{
		padding: 30px 0;
		font-size: 18px;
		line-height: 27px;
		display:none;
		&.active{ display:block; }
		
		h2{ margin: 0 0 10px; }
		p{ margin: 0 0 10px; }
		
		ul{ margin: 0 0 20px; line-height: 36px; }
		
		.block-image.conflict-svg + block-content{ padding-right: 7px; }
		
		
		.block-image.conflict-svg{ margin-right: -13px; }
		
		.m({
			.block-image.conflict-svg{ margin-right: 0; }
			.block-content + .block-image{ padding-right: 0; }
			.btn{ width:100%; }
			padding-bottom:0;
		});
	}
}