@import "../variables.less";
.section.list-grid{
	padding: 90px 0 75px;
	margin-bottom: 100px;
	
	.section-header{
		margin-bottom: 90px;
		.m({
			h2.title{
				font-size: 24px;
				line-height: 29px;
			}
			margin-bottom:40px;
		})
	}
	
	.m({
		padding: 50px 0;
		margin-bottom: 20px;
	});
	
	.list-grid-item{
		
		.icon{
			width: 160px;
			vertical-align: middle;
			text-align: center;
			.m({
				width:100px;
				padding: 13px;
				img{ max-width:100%; }
			});
		}
		.content{
			padding: 20px 20px 20px 0;
			font-size: 16px;
			line-height: 20px;
			.m({
				font-size: 14px;
				line-height: 18px;
			});
			h3{
				font-family: @headingfont;
				font-style: normal;
				font-weight: bold;
				font-size: 20px;
				line-height: 24px;
				margin-bottom:10px;
				color: #092984;
				.m({
					font-size: 18px;
					line-height: 22px;
				});
			}
			p{ margin: 0 0 20px; &:last-of-type{ margin-bottom:0; } }
			.btn.learn-more{
				padding: 2px 10px 3px;
				font-size: 14px;
			}
		}
		background: #FFFFFF;
		border: 1px solid #EBEEFE;
		box-sizing: border-box;
		box-shadow: 4px 9px 18px #ECEFFE;
		border-radius: 6px;
		transition: all 0.3s;
		&:hover{
			padding: 5px 0;
			margin: -5px -5px;
			box-shadow: 0px 18px 24px #D9DEF8, -9px -18px 18px rgba(236, 239, 254, 0.25);
		}
	}
	
	.grid.list-grid{
		margin-left:-20px;
		& > .grid__item{
			padding-left: 20px; margin-bottom: 40px;
			.m({
				margin-bottom: 35px;
			});
		}
	}
}