@import "../variables.less";
.section.related-blogs{
	padding: 80px 0 170px;
	.m({
		padding: 30px 0 30px;
	});
	.related-block-slider-wrap{
		width: 1134px;
		margin: 0 auto;
		overflow: hidden;
		max-width: 100%;
	}
	.slick-track
	{
		display: flex !important;
	}

	.slick-slide
	{
		height: inherit !important;
	}
	.slick-list{ overflow:visible; }
	.slick-slide{
		width: 401px;
		outline:0;
		.related-block{
			height: 294px;
			background-repeat:no-repeat !important;
			background-size:cover !important;
			background-position: center top !important;
			background: #FFFFFF;
			border: 1px solid #EBEEFE;
			box-sizing: border-box;
			/* PanelHover */

			box-shadow: 5px 32px 33px rgba(217, 222, 248, 0.43), -9px -18px 18px rgba(236, 239, 254, 0.25);
			border-radius: 6px;
			margin: 0 30px;
			.m({
				margin:0;
				height: 260px;
				width: 100%;
				position: relative;
			});
		}
	}
	.related-blogs-block{
		padding: 50px 0;
		.related-blog{
			display:table;
			width:100%;
			height:100%;
			.wrap{
				display:table-cell;
				vertical-align:middle;
				.m({ padding-left:10px; padding-right:10px; });
				.content{
					padding: 80px 36px 0;
					font-size: 16px;
					line-height: 20px;
					.m({
						bottom:14px;
						left:14px;
						font-size: 14px;
						line-height: 18px;
						padding: 80px 14px 20px;
						position:absolute;
					});
					h3{
						font-family: @headingfont;
						font-style: normal;
						font-weight: bold;
						font-size: 20px;
						line-height: 24px;
						margin-bottom:10px;
						color: #092984;
						.m({
							font-size: 18px;
							line-height: 22px;
						});
					}
					p{ margin: 0 0 20px; &:last-of-type{ margin-bottom:0; } }
					.btn.learn-more{
						padding: 2px 10px 3px;
						font-size: 14px;
					}
				}
			}
		}
		.slick-current{
			width: 321px;
			.sm({
				width: 260px;
			});
			position:relative;
			z-index:3;
			.related-block{
				height: 350px;
				opacity:1;
				margin:0 -40px;
				.content{
					padding: 120px 60px 0;
				}
				.m({
					position:relative;
					margin:0;
					height: 292px;
					.content{
						padding: 120px 14px 20px;
					}
				});
			}
		}
		
		.slick-active{
			.related-block{
				opacity:1;
			}
		}
		
	}
}