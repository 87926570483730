@import "../variables.less";
.section.element-table-block-section{
	h2{
		margin-bottom: 50px; font-family:@headingfont;
		.m({
			text-align:center;
			text-align: center;
			font-size: 24px;
			line-height: 29px;
			margin-bottom: 20px;
			br,br:before{ display:none; }
		});
	}
	h3.sub-heading{
		margin-bottom: 70px;
		.m({
			text-align:center;
			br,br:before{ display:none; }
			margin-bottom:40px;
		});
	}
	.element-block-image{
		position: absolute;
		z-index: -1;
		right: -180px;
		top: -70px;
		
		.m({
			left: 18px;
		});
	}
	width:100%;
	// overflow:hidden;
	//padding: 100px 0 200px;
	.m({
		padding: 100px 0 50px;
	});
	
	.container{ position:relative;  }
	
	.m({
		padding-top:0;
		.container{
			position:relative;
			padding-top:272px;
			padding-left:18px;
			padding-right:18px;
			background: url(../assets/header-fade.svg) no-repeat;
			background-position: center top 33px;
			background-size: 100% auto;
			margin:0;
		}
		.element-block-image{ top: 0; }
	});
}

.element-blocks{
	.element-block{
		background: #FFFFFF;
		border: 1px solid #E5E5E5;
		box-sizing: border-box;
		box-shadow: 0px 2px 4px rgba(2, 49, 181, 0.23), 0px -2px 2px rgba(255, 255, 255, 0.5);
		border-radius: 6px;
		width:161px;
		height:161px;
		margin: 0 18px 17px 0;
		display:table;
		position:relative;
		color: #0231B5;
		float:left;
		text-decoration: none;
		transition: all 0.3s;

		&:hover {
			background: #0231B5;
			color: white;
			span.element-abbr {
				opacity: 0;
			}
			span.element-title {
				bottom: 72px;
			}
			span.element-cta {
				opacity: 1;
			}
		}
		
		span.element-abbr{
			display:table-cell;
			vertical-align:middle;
			text-align:center;
			padding-bottom:13px;
			font-family: @headingfont;
			font-style: normal;
			font-weight: bold;
			font-size: 58px;
			line-height: 71px;
		}
		span.element-title{
			position:absolute;
			text-align:center;
			width:100%;			
			left:0;
			bottom:0;
			font-family: @headingfont;
			font-style: normal;
			font-weight: bold;
			font-size: 16px;
			line-height: 19px;
			padding: 15px;
			text-transform:uppercase;
		}
		span.element-cta{
			position:absolute;
			text-align:center;
			width:100%;			
			left:0;
			bottom:39px;
			font-family: @headingfont;
			font-style: normal;
			font-weight: normal;
			font-size: 14px;
			line-height: 19px;
			padding: 15px;
			text-transform:uppercase;
			opacity: 0;
		}
		&.clear-element{ clear:both; }
		&.no-margin-right{ margin-right:0; }
		&.offset-1{ margin-left:178px; }
		
		.m({
			margin-right:17px;
			
			&.clear-element{ clear:none; }
			&.offset-1{ margin-left:0; }
			&.no-margin-right{ margin-right: 17px; }
			
		});
		.sm({
			&.no-margin-right:nth-child(2n){ margin-right: 0; }
			&:nth-child(2n){ margin-right: 0; }
		});
	}
}
